import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ICONS_PATH } from '../constants.config';
import { environment } from './../../../environments/environment';
import { CustomIcon } from './../models/custom-icon.model';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class IconRegistrationService {
  icons: CustomIcon[] = [
    new CustomIcon('lang', `${environment.assets}images/lang.svg`),
    new CustomIcon('green', `${environment.assets}images/status-icons/Green.svg`),
    new CustomIcon('export_csv', `${environment.assets}images/ExportCsv.svg`),
    new CustomIcon('export_pdf', `${environment.assets}images/ExportPdf.svg`),
    new CustomIcon('export', `${environment.assets}images/Export.svg`),
    new CustomIcon('import', `${environment.assets}images/import.svg`),
    new CustomIcon('edit_custom', `${environment.assets}images/Edit.svg`),
    new CustomIcon('card_view', `${environment.assets}images/Card-View.svg`),
    new CustomIcon('list_view', `${environment.assets}images/List-View.svg`),
    new CustomIcon('filtering', `${environment.assets}images/Filtering.svg`),
    new CustomIcon('menu.dashboard', `${this.brandImagesAssets}menu-icons/MenuIcon-Dash.svg`),
    new CustomIcon('menu.acct_mgmnt', `${this.brandImagesAssets}menu-icons/MenuIcon-Account-management.svg`),
    new CustomIcon('menu.label_designer', `${this.brandImagesAssets}menu-icons/MenuIcon-LabelManager.svg`),
    new CustomIcon('menu.menu_mgr', `${this.brandImagesAssets}menu-icons/MenuIcon-MenuManager.svg`),
    new CustomIcon('menu.loc_mgr', `${this.brandImagesAssets}menu-icons/MenuIcon-LocationManager.svg`),
    new CustomIcon('menu.media_mgr', `${this.brandImagesAssets}menu-icons/MenuIcon-MediaManager.svg`),
    new CustomIcon('menu.analytics', `${this.brandImagesAssets}menu-icons/MenuIcon-Report.svg`),
    new CustomIcon('menu.nutrition_facts', `${this.brandImagesAssets}menu-icons/MenuIcon-NutritionFact.svg`),
    new CustomIcon('menu.settings', `${this.brandImagesAssets}menu-icons/MenuIcon-Settings.svg`),
    new CustomIcon('menu.resellers', `${this.brandImagesAssets}menu-icons/MenuIcon-LocationManager.svg`),
    new CustomIcon('exit_impersonation', `${environment.assets}images/ExitImpersonation.svg`),
    new CustomIcon('menus', `${this.brandImagesAssets}menu-icons/MenuIcon-MenuManager.svg`),
    new CustomIcon('products', `${environment.assets}images/menu-manager/MenuItems.svg`),
    new CustomIcon('ingredients', `${environment.assets}images/menu-manager/Ingredients.svg`),
    new CustomIcon('addons', `${environment.assets}images/menu-manager/Combos.svg`),
    new CustomIcon('tasks', `${environment.assets}images/menu-manager/Task.svg`),
    new CustomIcon('recipes', `${environment.assets}images/menu-manager/Recipe.svg`),
    new CustomIcon('price_fill', `${environment.assets}images/menu-manager/Price_Fill.svg`),
    new CustomIcon('grouptags', `${environment.assets}images/menu-manager/GroupTag.svg`),
    new CustomIcon('terminalUser', `${environment.assets}images/TerminalUser.svg`),
    new CustomIcon('inherited', `${environment.assets}images/Inh.svg`),
    new CustomIcon('portalUser', `${environment.assets}images/DeviceOth.svg`),
    new CustomIcon('folder', `${environment.assets}images/Node_Folder.svg`),
    new CustomIcon('folder-open', `${environment.assets}images/Folder-Open.svg`),
    new CustomIcon('store', `${environment.assets}images/Store.svg`),
    new CustomIcon('new_folder', `${environment.assets}images/NewFolder.svg`),
    new CustomIcon('customized', `${environment.assets}images/CustomizedMenu.svg`),
    new CustomIcon('menu-published', `${environment.assets}/images/PublishMenuDone_dark.svg`),
    new CustomIcon('menu-subscribed', `${environment.assets}images/SubscriptionCircle.svg`),
    new CustomIcon('timer-subscribed', `${environment.assets}images/SubscriptionCircle.svg`),
    new CustomIcon('checklist-subscribed', `${environment.assets}images/SubscriptionCircle.svg`),
    new CustomIcon('media-subscribed', `${environment.assets}images/SubscriptionCircle.svg`),
    new CustomIcon('equipment-subscribed', `${environment.assets}images/SubscriptionCircle.svg`),
    new CustomIcon('equipment-sensors', `${environment.assets}images/sensors.svg`),
    new CustomIcon('view-actual-size', `${environment.assets}/images/ViewActualSize.svg`),
    new CustomIcon('portrait', `${environment.assets}/images/portrait.svg`),
    new CustomIcon('landscape', `${environment.assets}/images/landscape.svg`),
    new CustomIcon('zoom-in', `${environment.assets}/images/zoom in.svg`),
    new CustomIcon('zoom-out', `${environment.assets}/images/zoom out.svg`),
    new CustomIcon('checklist-published', `${environment.assets}/images/PublishMenuDone_dark.svg`),
    new CustomIcon('checklist-queued', `${environment.assets}/images/PublishMenu_dark.svg`),
    new CustomIcon('user', `${this.brandImagesAssets}menu-icons/user-icon.svg`),
    new CustomIcon('checklists', `${environment.assets}images/Checklists.svg`),
    new CustomIcon('ops', `${this.brandImagesAssets}ops.svg`),
    new CustomIcon('temp', `${this.brandImagesAssets}Temp.svg`),
    new CustomIcon('arrow_up_down', `${environment.assets}images/move_alt_icons/move_alt-1.svg`),
    new CustomIcon('arrow_all', `${environment.assets}images/move_alt_icons/move_alt-2.svg`),
    new CustomIcon('arrow_left', `${environment.assets}images/move_alt_icons/move_alt-3.svg`),
    new CustomIcon('arrow_right', `${environment.assets}images/move_alt_icons/move_alt-5.svg`),
    new CustomIcon('arrow_up_down_right', `${environment.assets}images/move_alt_icons/move_alt-6.svg`),
    new CustomIcon('arrow_left_right', `${environment.assets}images/move_alt_icons/move_alt-4.svg`),
    new CustomIcon('opschecklist', `${this.brandImagesAssets}OpsChecklist.svg`),
    new CustomIcon('tempchecklist', `${this.brandImagesAssets}TempChecklist.svg`),
    new CustomIcon('employeeSurvey', `${this.brandImagesAssets}EmployeeSurvey.svg`),
    new CustomIcon('subscription', `${environment.assets}images/Menu-Subscribe.svg`),
    new CustomIcon('locationPin', `${environment.assets}images/LocationPin.svg`),
    new CustomIcon('locationPinGrey', `${environment.assets}images/LocationPinGrey.svg`),
    new CustomIcon('locationPinHalfGrey', `${environment.assets}images/LocationPinHalfGrey.svg`),
    new CustomIcon('dialog-icon', `${environment.assets}images/InputTypesIc.svg`),
    new CustomIcon('warning', `${environment.assets}${ICONS_PATH.WARNING}`),
    new CustomIcon('red_warning', ICONS_PATH.WARNING_RED),
    new CustomIcon('timers', `${this.brandImagesAssets}menu-icons/timer.svg`),
    new CustomIcon('locked', `${environment.assets}images/Lock.svg`),
    new CustomIcon('qr_code_scan', `${environment.assets}images/qr code scan.svg`),
    new CustomIcon('add', `${environment.assets}images/AddTranslation.svg`),
    new CustomIcon('edit', `${environment.assets}images/EditTranslation.svg`),
    new CustomIcon('view', `${environment.assets}images/ViewTranslation.svg`),
    new CustomIcon('snap-to-top', `${environment.assets}images/label-designer/snap-to/snap-to-top.svg`),
    new CustomIcon('snap-to-bottom', `${environment.assets}images/label-designer/snap-to/snap-to-bottom.svg`),
    new CustomIcon('snap-to-left', `${environment.assets}images/label-designer/snap-to/snap-to-left.svg`),
    new CustomIcon('snap-to-right', `${environment.assets}images/label-designer/snap-to/snap-to-right.svg`),
    new CustomIcon('align_top', `${environment.assets}images/label-designer/align/align_top.svg`),
    new CustomIcon('align_bottom', `${environment.assets}images/label-designer/align/align_bottom.svg`),
    new CustomIcon('align_left', `${environment.assets}images/label-designer/align/align_left.svg`),
    new CustomIcon('align_right', `${environment.assets}images/label-designer/align/align_right.svg`),
    new CustomIcon('align_center', `${environment.assets}images/label-designer/align/align_center.svg`),
    new CustomIcon('align_middle', `${environment.assets}images/label-designer/align/align_middle.svg`),
    new CustomIcon('editor_layers', `${environment.assets}images/label-designer/layers.svg`),
    new CustomIcon('editor_config', `${environment.assets}images/label-designer/config.svg`),
    new CustomIcon('object-icon-newBarcode', `${environment.assets}images/label-designer/object-icon-barcode.svg`),
    new CustomIcon('object-icon-calculatedField', `${environment.assets}images/label-designer/object-icon-calculated-field.svg`),
    new CustomIcon('object-icon-compositeField', `${environment.assets}images/label-designer/object-icon-composite-field.svg`),
    new CustomIcon('object-icon-date', `${environment.assets}images/label-designer/object-icon-date.svg`),
    new CustomIcon('object-icon-dow', `${environment.assets}images/label-designer/object-icon-dow.svg`),
    new CustomIcon('object-icon-email', `${environment.assets}images/label-designer/object-icon-email.svg`),
    new CustomIcon('object-icon-employee', `${environment.assets}images/label-designer/object-icon-employee.svg`),
    new CustomIcon('object-icon-image', `${environment.assets}images/label-designer/object-icon-image.svg`),
    new CustomIcon('object-icon-labeling-logo', `${environment.assets}images/label-designer/object-icon-labeling-logo.svg`),
    new CustomIcon('object-icon-line', `${environment.assets}images/label-designer/object-icon-line.svg`),
    new CustomIcon('object-icon-location', `${environment.assets}images/label-designer/object-icon-location.svg`),
    new CustomIcon('object-icon-menu', `${environment.assets}images/label-designer/object-icon-menu.svg`),
    new CustomIcon('object-icon-nutrfact', `${environment.assets}images/label-designer/object-icon-nutrfact.svg`),
    new CustomIcon('object-icon-storePhone', `${environment.assets}images/label-designer/object-icon-phone-number.svg`),
    new CustomIcon('object-icon-rectangle', `${environment.assets}images/label-designer/object-icon-rectangle.svg`),
    new CustomIcon('object-icon-label', `${environment.assets}images/label-designer/object-icon-text.svg`),
    new CustomIcon('object-icon-widget', `${environment.assets}images/label-designer/object-icon-widget.svg`),
    new CustomIcon('undo', `${environment.assets}images/label-designer/undo.svg`),
    new CustomIcon('download-file', `${environment.assets}images/download-file.svg`),
    new CustomIcon('order-scale', `${environment.assets}images/OrderScale.svg`),
    new CustomIcon('catalogs', `${environment.assets}images/catalog-manager/Catalog.svg`),
    new CustomIcon('catalogProduct', `${environment.assets}images/catalog-manager/Product.svg`),
    new CustomIcon('object-icon-calories', `${environment.assets}images/label-designer/object-icon-calories.svg`),
    new CustomIcon('object-icon-sku', `${environment.assets}images/label-designer/object-icon-sku.svg`),
    new CustomIcon('object-icon-color', `${environment.assets}images/label-designer/object-icon-color.svg`),
    new CustomIcon('object-icon-size', `${environment.assets}images/label-designer/object-icon-size.svg`),
    new CustomIcon('object-icon-price', `${environment.assets}images/label-designer/object-icon-price.svg`),
    new CustomIcon('object-icon-department', `${environment.assets}images/label-designer/object-icon-department.svg`),
    new CustomIcon('object-icon-discount', `${environment.assets}images/label-designer/object-icon-discount.svg`),
    new CustomIcon('object-icon-batch-count', `${environment.assets}images/label-designer/object-icon-batch-count.svg`),
    new CustomIcon('object-icon-voice-pick', `${environment.assets}images/label-designer/object-icon-voice-pick.svg`),
    new CustomIcon('refresh', `${environment.assets}images/label-designer/ios-refresh.svg`),
    new CustomIcon('twitter-x', `${environment.assets}images/twitter-x.svg`),
    new CustomIcon('chart', `${environment.assets}images/chart.svg`),
    new CustomIcon('tabular', `${environment.assets}images/tabular.svg`),
    new CustomIcon('maximize', `${environment.assets}images/maximize.svg`),
    new CustomIcon('minimize', `${environment.assets}images/minimize.svg`),
    new CustomIcon('breadcrumb-arrow', `${environment.assets}images/breadcrumb arrow.svg`),
    new CustomIcon('search', `${environment.assets}images/search.svg`),
    new CustomIcon('plus_circle', `${environment.assets}images/plus_circle.svg`),
    new CustomIcon('trash', `${environment.assets}images/trash.svg`)
  ];
  constructor(private matIconRegistryService: MatIconRegistry, private domSanitizer: DomSanitizer, private themeService: ThemeService) {}

  registerIcons() {
    this.icons.forEach((icon) => {
      const iconUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url);
      this.matIconRegistryService.addSvgIcon(icon.name, iconUrl);
    });
  }

  private get brandImagesAssets(): string {
    return this.themeService.getbrandImagesAssets();
  }
}
