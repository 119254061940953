import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarModule } from 'angular-calendar';
import { OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { DndModule } from 'ngx-drag-drop';
import { JoyrideModule } from 'ngx-joyride';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FeedbackDialogComponent } from '../modules/location-manager/components/dialogs/feedback-dialog/feedback-dialog.component';
import { PublishMenuDialogComponent } from '../modules/location-manager/components/dialogs/publish-menu-dialog/publish-menu-dialog.component';
import { LocationPublishAllDataDialogComponent } from '../modules/location-manager/components/dialogs/location-publish-all-data-dialog/location-publish-all-data-dialog.component';
import { NewMediaDialogComponent } from '../modules/media-manager/components/new-media-dialog/new-media-dialog.component';
import { ConfirmProceedDialogComponent } from '../modules/menu-manager/components/dialogs/confirm-proceed-dialog/confirm-proceed-dialog.component';
import { DeleteChecklistSectionComponent } from '../modules/menu-manager/components/dialogs/delete-checklist-section/delete-checklist-section.component';
import { DeleteChecklistTaskComponent } from '../modules/menu-manager/components/dialogs/delete-checklist-task/delete-checklist-task.component';
import { DeleteSectionComponent } from '../modules/menu-manager/components/dialogs/delete-section/delete-section.component';
import { RemoveItemDialogComponent } from '../modules/menu-manager/components/dialogs/remove-item-dialog/remove-item-dialog.component';
import { RenameItemDialogComponent } from '../modules/menu-manager/components/dialogs/rename-item-dialog/rename-item-dialog.component';
import { SectionPopupComponent } from '../modules/menu-manager/components/dialogs/section-popup/section-popup.component';
import { DeleteTaskComponent } from '../modules/settings/components/dialogs/delete-task/delete-task.component';
import { AutocompleteFieldComponent } from './components/autocomplete-field/autocomplete-field.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BrandSelectorComponent } from './components/brand-selector/brand-selector.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { EventMenuTemplateComponent } from './components/calendar-templates/event-actions-templates/event-menu-template/event-menu-template.component';
import { EventTitleDescriptionTemplateComponent } from './components/calendar-templates/event-title-templates/event-title-description-template/event-title-description-template.component';
import { CancelConfirmComponent } from './components/cancel-confirm/cancel-confirm.component';
import { ChecklistNodeComponent } from './components/checklist-node/checklist-node.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ConflictDialogComponent } from './components/conflict-dialog/conflict-dialog.component';
import { CronEditorTimePickerComponent } from './components/cron-editor-time-picker/cron-editor-time-picker.component';
import { CronEditorComponent } from './components/cron-editor/cron-editor.component';
import { CustomCalendarComponent } from './components/custom-calendar/custom-calendar.component';
import { CustomChipsListComponent } from './components/custom-chips-list/custom-chips-list.component';
import { DetailsPanelComponent } from './components/details-panel/details-panel.component';
import { DeviceNodeComponent } from './components/device-node/device-node.component';
import { CloneDialogComponent } from './components/dialogs/clone-dialog/clone-dialog.component';
import { InformativeDialogComponent } from './components/dialogs/informative-dialog/informative-dialog.component';
import { PublishDialogComponent } from './components/dialogs/publish-dialog/publish-dialog.component';
import { EquipmentNodeComponent } from './components/equipment-node/equipment-node.component';
import { EquipmentTypeDialogComponent } from './components/equipment-type-dialog/equipment-type-dialog.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FolderCardComponent } from './components/folder-card/folder-card.component';
import { FullChipItemsComponent } from './components/full-chip-items/full-chip-items.component';
import { HorizontalLibraryScrollComponent } from './components/horizontal-library-scroll/horizontal-library-scroll.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { InfoDisplayPanelComponent } from './components/info-display-panel/info-display-panel.component';
import { LocationsDropdownComponent } from './components/locations-dropdown/locations-dropdown.component';
import { MediaNodeComponent } from './components/media-node/media-node.component';
import { MediaShowcaseComponent } from './components/media-showcase/media-showcase.component';
import { MenuBatchPrintComponent } from './components/menu-batch-print/menu-batch-print.component';
import { MenuBuilderComponent } from './components/menu-builder/menu-builder.component';
import { MenuNodeComponent } from './components/menu-node/menu-node.component';
import { MultiBatchPrintSelectComponent } from './components/multi-batch-print-select/multi-batch-print-select.component';
import { NewCategoryFormComponent } from './components/new-category-form/new-category-form.component';
import { NomeCustomCardComponent } from './components/nome-custom-card/nome-custom-card.component';
import { NomeDisplayedItemsLabelComponent } from './components/nome-displayed-items-label/nome-displayed-items-label.component';
import { NomeMediaCardComponent } from './components/nome-media-card/nome-media-card.component';
import { NomeRoleCardComponent } from './components/nome-role-card/nome-role-card.component';
import { NomecardComponent } from './components/nomecard/nome-card.component';
import { NoresultsfoundComponent } from './components/noresultsfound/noresultsfound.component';
import { SaveCancelComponent } from './components/save-cancel/save-cancel.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SearchComponent } from './components/search/search.component';
import { SelectableListComponent } from './components/selectable-list/selectable-list.component';
import { StylesDropDownComponent } from './components/styles-drop-down/styles-drop-down.component';
import { TablePaginatorComponent } from './components/table-paginator/table-paginator.component';
import { TableComponent } from './components/table/table.component';
import { TaggingComponent } from './components/tagging/tagging.component';
import { TitleAndViewSwitchComponent } from './components/title-and-view-switch/title-and-view-switch.component';
import { TreeComponent } from './components/tree/tree.component';
import { UnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { UpdateAppComponent } from './components/update-app/update-app.component';
import { WorkflowTemplatesComponent } from './components/workflow-templates/workflow-templates.component';
import { AutoDisableDirective } from './directives/auto-disable.directive';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { OnlyFullNumberDirective } from './directives/only-full-number.directive';
import { PhoneNumberFormattingDirective } from './directives/phone-number-format.directive';
import { FilterPipe } from './pipes/filter.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { PhoneNumberFormattingPipe } from './pipes/phone-number-format.pipe';
import { LocalizationService } from './services/localization/localization.service';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DropdownWithSearchComponent } from './components/dropdown-with-search/dropdown-with-search.component';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component';
import { EquipmentAssignedToChecklistComponent } from './components/dialogs/equipment-assigned-to-checklist/equipment-assigned-to-checklist.component';
import { QuickFiltersComponent } from './components/quick-filters/quick-filters.component';
import { NomeCustomFloatButtonModule } from '../core/nome-custom-float-button/nome-custom-float-button.module';
import { CustomImageViewerModule } from '../components/custom-image-viewer/custom-image-viewer.module';
import { ChecklistSubscriptionErrorDialogComponent } from './components/checklist-subscription-error-dialog/checklist-subscription-error-dialog.component';
import { CustomizeLocationMenuComponent } from './components/customize-location-menu/customize-location-menu.component';
import { CheckboxTableComponent } from './components/checkbox-table/checkbox-table.component';
import { KeyValueDisplayComponent } from './components/key-value-display/key-value-display.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { RevertMenuConfirmDialog } from './components/dialogs/revert-menu-confirm-dialog/revert-menu-confirm-dialog.component';
import { CardWithContextMenuComponent } from './components/card-with-context-menu/card-with-context-menu.component';
import { BulkMediaUploadDialogComponent } from './components/bulk-media-upload-dialog/bulk-media-upload-dialog.component';
import { FileNameRestrictionPipe } from './pipes/file-name-restriction.pipe';
import { ngfModule } from 'angular-file';
import { LegendComponent } from './components/legend/legend.component';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { HasPermissionDirectiveV2 } from './directives/has-permission-v2.directive';
import { EditableNumericTableCellComponent } from './components/editable-numeric-table-cell/editable-numeric-table-cell.component';
import { ScrollToInvalidFieldDirective } from './directives/scroll-to-invalid-field.directive';
import { IconWithLabelButtonComponent } from './components/icon-with-label-button/icon-with-label-button.component';
import { InputFieldDialogComponent } from './components/dialogs/input-field-dialog/input-field-dialog.component';
import { SelectionListWithSearchComponent } from './components/selection-list-with-search/selection-list-with-search.component';
import { TreeDialogComponent } from './components/dialogs/tree-dialog/tree-dialog.component';
import { InputTextareaFieldsDialogComponent } from './components/dialogs/input-textarea-fields-dialog/input-textarea-fields-dialog.component';
import { CustomContextMenuModule } from '../components/custom-context-menu/custom-context-menu.module';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { SelectComponent } from './components/select/select.component';
import { EditableTextTableCellComponent } from './components/editable-text-table-cell/editable-text-table-cell.component';
import { LabelsDropdownComponent } from './components/labels-dropdown/labels-dropdown.component';
import { LabelNamePipe } from './pipes/label-name.pipe';
import { TimerNodeComponent } from './components/timer-node/timer-node.component';
import { PublishTimerDialogComponent } from '../modules/location-manager/components/dialogs/publish-timer-dialog/publish-timer-dialog.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ParagraphCasePipe } from './pipes/paragraph-case.pipe';
import { FlavoredMessageModule } from './modules/flavored-message/flavored-message.module';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { MultipleChoiceSelectorComponent } from './components/multiple-choice-selector/multiple-choice-selector.component';
import { MatTableColumnHeaderFocusDisableDirective } from './directives/mat-table-column-header-focus-disable.directive';
import { NoSpecialCharsDirective } from './directives/no-special-chars.directive';
import { EditableDialogTableCellComponent } from './components/editable-dialog-table-cell/editable-dialog-table-cell.component';
import { TitleCaseModule } from '../pipes/title-case/title-case.module';
import { TitleCasePipe } from '../pipes/title-case/title-case.pipe';
import { TrimDirective } from './directives/trim.directive';
import { DisableEnterDirective } from './directives/disable-enter.directive';
import { CarouselComponent } from './components/carousel/carousel.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { MultipleSelectWithSearchComponent } from './components/multiple-select-with-search/multiple-select-with-search.component';
import { DialogRelatedModule } from './modules/dialog-related/dialog-related.module';
import { FormErrorsModule } from '../pipes/form-errors/form-errors.module';
import { myCustomTooltipDefaults } from './constant/mat-tooltip-const-settings';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { MoveDialogComponent } from './components/dialogs/move-dialog/move-dialog.component';
import { PermissionCardComponent } from './components/permission-card/permission-card.component';
import { TreeMenuSelectionComponent } from './components/tree-menu-selection/tree-menu-selection.component';
import { ToolTipRendererDirective } from './directives/tool-tip-renderer.directive';
import { SubscribersDropdownComponent } from './components/subscribers-dropdown/subscribers-dropdown.component';
import { StoresListDropdownComponent } from './components/stores-list-dropdown/stores-list-dropdown.component';
import { CobrowseDialogComponent } from './components/dialogs/cobrowse-dialog/cobrowse-dialog.component';
import { TranslateAndCaseModule } from '../pipes/translate-and-case/translate-and-case.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PublishErrorDialogComponent } from './components/dialogs/publish-error-dialog/publish-error-dialog.component';
import { CatalogNodeComponent } from './components/catalog-node/catalog-node.component';
import { AddButtonContentComponent } from './components/add-button-content/add-button-content.component';
import { SideFilterComponent } from './components/side-filter/side-filter.component';
import { AccountsListComponent } from './components/accounts-list/accounts-list.component';

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
  constructor(private translateService: TranslateService, private titlecasePipe: TitleCasePipe) {
    super();
    setTimeout(() => {
      this.cancelBtnLabel = this.titlecasePipe.transform(this.translateService.instant('cancel'));
      this.setBtnLabel = this.titlecasePipe.transform(this.translateService.instant('set'));
    }, 100);
    this.translateService.onLangChange.subscribe((res) => {
      this.cancelBtnLabel = this.titlecasePipe.transform(this.translateService.instant('cancel'));
      this.setBtnLabel = this.titlecasePipe.transform(this.translateService.instant('set'));
    });
  }
}

export function getCurrentLangFactory(localizationService: LocalizationService) {
  //console.log('setting provider value', localizationService.$currentLanguage.value);
  return localizationService.$currentLanguage.value;
}

@NgModule({
  declarations: [
    MoveDialogComponent,
    LabelNamePipe,
    LabelsDropdownComponent,
    TablePaginatorComponent,
    TitleAndViewSwitchComponent,
    NomecardComponent,
    SaveCancelComponent,
    UpdatePasswordComponent,
    FilterPipe,
    SelectComponent,
    KeysPipe,
    TaggingComponent,
    LegendComponent,
    TreeComponent,
    AutocompleteFieldComponent,
    SelectableListComponent,
    SearchComponent,
    NomeRoleCardComponent,
    NomeCustomCardComponent,
    NomeDisplayedItemsLabelComponent,
    NewCategoryFormComponent,
    DetailsPanelComponent,
    ConfirmationComponent,
    PhoneNumberInputComponent,
    OnlyNumberDirective,
    OnlyFullNumberDirective,
    FolderCardComponent,
    BrandSelectorComponent,
    MenuBuilderComponent,
    StylesDropDownComponent,
    TableComponent,
    NoresultsfoundComponent,
    NewMediaDialogComponent,
    UnsavedChangesDialogComponent,
    FeedbackDialogComponent,
    BreadcrumbsComponent,
    HasPermissionDirective,
    HasPermissionDirectiveV2,
    AutoDisableDirective,
    MatTableColumnHeaderFocusDisableDirective,
    InfoDisplayPanelComponent,
    ImageUploadComponent,
    ConflictDialogComponent,
    ConfirmProceedDialogComponent,
    MenuBatchPrintComponent,
    MultiBatchPrintSelectComponent,
    NomeMediaCardComponent,
    ChecklistNodeComponent,
    PhoneNumberFormattingPipe,
    PhoneNumberFormattingDirective,
    PublishMenuDialogComponent,
    LocationPublishAllDataDialogComponent,
    DeviceNodeComponent,
    MenuNodeComponent,
    MediaNodeComponent,
    CancelConfirmComponent,
    MediaShowcaseComponent,
    UpdateAppComponent,
    CustomCalendarComponent,
    HorizontalLibraryScrollComponent,
    WorkflowTemplatesComponent,
    DeleteTaskComponent,
    CronEditorComponent,
    CronEditorComponent,
    CronEditorTimePickerComponent,
    EquipmentNodeComponent,
    EquipmentTypeDialogComponent,
    BackButtonComponent,
    LocationsDropdownComponent,
    SectionPopupComponent,
    DeleteSectionComponent,
    RemoveItemDialogComponent,
    RenameItemDialogComponent,
    DeleteChecklistSectionComponent,
    DeleteChecklistTaskComponent,
    CloneDialogComponent,
    PublishDialogComponent,
    InformativeDialogComponent,
    CobrowseDialogComponent,
    FileUploadComponent,
    EventTitleDescriptionTemplateComponent,
    EventMenuTemplateComponent,
    SearchInputComponent,
    CustomChipsListComponent,
    FullChipItemsComponent,
    CapitalizePipe,
    DropdownWithSearchComponent,
    InfiniteScrollDirective,
    ImageDialogComponent,
    EquipmentAssignedToChecklistComponent,
    QuickFiltersComponent,
    ChecklistSubscriptionErrorDialogComponent,
    CustomizeLocationMenuComponent,
    CheckboxTableComponent,
    KeyValueDisplayComponent,
    DatePickerComponent,
    RevertMenuConfirmDialog,
    CardWithContextMenuComponent,
    BulkMediaUploadDialogComponent,
    FileNameRestrictionPipe,
    EditableNumericTableCellComponent,
    ScrollToInvalidFieldDirective,
    ToolTipRendererDirective,
    InputFieldDialogComponent,
    SelectionListWithSearchComponent,
    TreeDialogComponent,
    InputTextareaFieldsDialogComponent,
    ContextMenuComponent,
    EditableTextTableCellComponent,
    TimerNodeComponent,
    PublishTimerDialogComponent,
    ClickOutsideDirective,
    ParagraphCasePipe,
    MultipleChoiceSelectorComponent,
    NoSpecialCharsDirective,
    EditableDialogTableCellComponent,
    TrimDirective,
    DisableEnterDirective,
    CarouselComponent,
    InfoDialogComponent,
    MultipleSelectWithSearchComponent,
    CookieConsentComponent,
    PermissionCardComponent,
    TreeMenuSelectionComponent,
    SubscribersDropdownComponent,
    StoresListDropdownComponent,
    PublishErrorDialogComponent,
    CatalogNodeComponent,
    SideFilterComponent,
    AccountsListComponent
  ],
  imports: [
    AddButtonContentComponent,
    IconWithLabelButtonComponent,
    TranslateAndCaseModule,
    DialogRelatedModule,
    FlavoredMessageModule,
    CustomContextMenuModule,
    MatDialogModule,
    MatTabsModule,
    TranslateModule,
    MatToolbarModule,
    ScrollingModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    CommonModule,
    MatChipsModule,
    MatIconModule,
    CalendarModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTreeModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatTooltipModule,
    NomeCustomFloatButtonModule,
    MatDividerModule,
    MatDividerModule,
    MatListModule,
    CdkTableModule,
    MatTableModule,
    MatPaginatorModule,
    MatStepperModule,
    MatGridListModule,
    DndModule,
    MatSelectModule,
    DragDropModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatSortModule,
    JoyrideModule.forChild(),
    MatSidenavModule,
    MatProgressBarModule,
    NgScrollbarModule,
    MatBadgeModule,
    CustomImageViewerModule,
    MatDatepickerModule,
    ngfModule,
    TitleCaseModule,
    FormErrorsModule
  ],
  exports: [
    AddButtonContentComponent,
    ScrollingModule,
    DialogRelatedModule,
    FlavoredMessageModule,
    FormErrorsModule,
    LabelNamePipe,
    LabelsDropdownComponent,
    TreeDialogComponent,
    SelectionListWithSearchComponent,
    InputFieldDialogComponent,
    TranslateModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    UpdatePasswordComponent,
    MatButtonModule,
    LegendComponent,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    PhoneNumberInputComponent,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    SaveCancelComponent,
    MatTabsModule,
    MatExpansionModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatBottomSheetModule,
    ColorPickerModule,
    KeysPipe,
    MatDatepickerModule,
    SelectComponent,
    MatNativeDateModule,
    CommonModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatDividerModule,
    MatDialogModule,
    MatChipsModule,
    TaggingComponent,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    NomecardComponent,
    NomeRoleCardComponent,
    NomeMediaCardComponent,
    AutocompleteFieldComponent,
    MatSlideToggleModule,
    MatTreeModule,
    TreeComponent,
    MatProgressSpinnerModule,
    FilterPipe,
    NomeCustomFloatButtonModule,
    MatTooltipModule,
    SelectableListComponent,
    SearchComponent,
    CdkTableModule,
    MatTableModule,
    MatPaginatorModule,
    MatStepperModule,
    MatGridListModule,
    DndModule,
    DragDropModule,
    NomeCustomCardComponent,
    NomeDisplayedItemsLabelComponent,
    OnlyNumberDirective,
    OnlyFullNumberDirective,
    NewCategoryFormComponent,
    BrandSelectorComponent,
    FolderCardComponent,
    DetailsPanelComponent,
    TablePaginatorComponent,
    TitleAndViewSwitchComponent,
    MenuBuilderComponent,
    MenuBatchPrintComponent,
    StylesDropDownComponent,
    TableComponent,
    NewMediaDialogComponent,
    NoresultsfoundComponent,
    FeedbackDialogComponent,
    BreadcrumbsComponent,
    HasPermissionDirective,
    HasPermissionDirectiveV2,
    AutoDisableDirective,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    InfoDisplayPanelComponent,
    ImageUploadComponent,
    HorizontalLibraryScrollComponent,
    WorkflowTemplatesComponent,
    ConflictDialogComponent,
    MatSortModule,
    PhoneNumberFormattingPipe,
    PhoneNumberFormattingDirective,
    ConfirmProceedDialogComponent,
    DeviceNodeComponent,
    MenuNodeComponent,
    ChecklistNodeComponent,
    MediaNodeComponent,
    CancelConfirmComponent,
    MediaShowcaseComponent,
    CustomCalendarComponent,
    MatProgressBarModule,
    NgScrollbarModule,
    MatBadgeModule,
    CalendarModule,
    CronEditorComponent,
    DeleteTaskComponent,
    EquipmentNodeComponent,
    EquipmentTypeDialogComponent,
    BackButtonComponent,
    LocationsDropdownComponent,
    DeleteChecklistTaskComponent,
    CloneDialogComponent,
    PublishDialogComponent,
    InformativeDialogComponent,
    CobrowseDialogComponent,
    FileUploadComponent,
    EventTitleDescriptionTemplateComponent,
    EventMenuTemplateComponent,
    SearchInputComponent,
    CustomChipsListComponent,
    FullChipItemsComponent,
    CapitalizePipe,
    DropdownWithSearchComponent,
    InfiniteScrollDirective,
    CustomImageViewerModule,
    QuickFiltersComponent,
    ChecklistSubscriptionErrorDialogComponent,
    CustomizeLocationMenuComponent,
    CheckboxTableComponent,
    KeyValueDisplayComponent,
    DatePickerComponent,
    RevertMenuConfirmDialog,
    CardWithContextMenuComponent,
    BulkMediaUploadDialogComponent,
    ScrollToInvalidFieldDirective,
    ToolTipRendererDirective,
    IconWithLabelButtonComponent,
    InputTextareaFieldsDialogComponent,
    TimerNodeComponent,
    ClickOutsideDirective,
    ParagraphCasePipe,
    MultipleChoiceSelectorComponent,
    NoSpecialCharsDirective,
    TitleCaseModule,
    TrimDirective,
    DisableEnterDirective,
    MultipleSelectWithSearchComponent,
    MoveDialogComponent,
    PermissionCardComponent,
    TreeMenuSelectionComponent,
    SubscribersDropdownComponent,
    StoresListDropdownComponent,
    CatalogNodeComponent,
    SideFilterComponent,
    AccountsListComponent
  ],
  providers: [
    PhoneNumberFormattingPipe,
    { provide: MAT_DATE_LOCALE, useFactory: getCurrentLangFactory, deps: [LocalizationService] },
    { provide: OWL_DATE_TIME_LOCALE, useFactory: getCurrentLangFactory, deps: [LocalizationService] },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
  ]
})
export class SharedModule {}
