import { UserModulePermissions } from '../../shared/models/user-roles.model';
import { Modules } from '../../shared/models/enums/Modules.enum';
import { Permissions } from '../../shared/models/enums/Permissions.enum';
import { ICONS_PATH } from '../../shared/constants.config';
import { LocalStorageFlags } from '../../shared/models/enums/local-storage-flags.enum';
import { HelpAndSupport } from '../../shared/models/help-and-support.enum';
import { TenantTypeEnum } from '../../shared/models/tenant-type.model';

export const userAuthModules = {
  allModules: [
    {
      moduleName: Modules.REPORTING_HIERARCHY,
      pages: [
        {
          link: '^/settings/hierarchies$'
        },
        {
          link: '^/settings/hierarchies/[0-9]+$'
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Label Sizes',
      pages: [
        {
          link: '^/settings/labelsize$',
          authorizedBylocalKey: ['hasLabelingActivated', 'hasTimersActivated']
        }
      ]
    },
    {
      moduleName: Modules.FIXED_READERS,
      pages: [
        {
          link: '^/hardware-manager/hardware-devices$',
          authorizedBylocalKey: [LocalStorageFlags.hasFixedReaders],
          authorizedByEnvironmentKey: ['showHardwareManagerModule']
        },
        {
          link: '^/hardware-manager/configure-device',
          authorizedBylocalKey: [LocalStorageFlags.hasFixedReaders],
          authorizedByEnvironmentKey: ['showHardwareManagerModule','logistics.showHardwareDeviceDetailedConfigOption']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: Modules.FIXED_READERS,
      pages: [
        {
          link: '^/settings/device-models$',
          authorizedBylocalKey: ['isReseller'],
          authorizedByEnvironmentKey: ['showHardwareManagerModule']
        }
      ],
      isAllowedForReseller: true
    },
    {
      moduleName: 'Orders',
      pages: [
        {
          link: '^/inventory-manager/orders$',
          authorizedBylocalKey: [LocalStorageFlags.HasOrdersActivated]
        },
        {
          link: '^/inventory-manager/orders/details/[0-9]+$',
          authorizedBylocalKey: [LocalStorageFlags.HasOrdersActivated]
        },
        {
          link: '^/inventory-manager/orders/new$',
          authorizedBylocalKey: [LocalStorageFlags.HasOrdersActivated]
        },
        {
          link: '^/inventory-manager/orders/edit/[0-9]+$',
          authorizedBylocalKey: [LocalStorageFlags.HasOrdersActivated]
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: Modules.ORDERING_TEMPLATES,
      pages: [
        {
          link: '^/inventory-manager/templates/new$',
          authorizedBylocalKey: [LocalStorageFlags.HasOrdersActivated]
        },
        {
          link: '^/inventory-manager/templates$',
          authorizedBylocalKey: [LocalStorageFlags.HasOrdersActivated]
        },
        {
          link: '^/inventory-manager/templates/details/[0-9]+$',
          authorizedBylocalKey: [LocalStorageFlags.HasOrdersActivated]
        },
        {
          link: '^/inventory-manager/templates/edit/[0-9]+$',
          authorizedBylocalKey: [LocalStorageFlags.HasOrdersActivated]
        },
        {
          link: '^/inventory-manager/templates/design/[0-9]+$',
          authorizedBylocalKey: [LocalStorageFlags.HasOrdersActivated]
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Invoices',
      pages: [
        {
          link: '^/inventory-manager/invoices$',
          authorizedBylocalKey: ['hasInvoicesActivated']
        },
        {
          link: '^/inventory-manager/invoices/details/[0-9]+$',
          authorizedBylocalKey: ['hasInvoicesActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: Modules.ORDER_GUIDES,
      pages: [
        {
          link: '^/inventory-manager/order-guides/.*$',
          authorizedBylocalKey: ['hasOrderGuidesActivated']
        },
        {
          link: '^/inventory-manager/order-guides$',
          authorizedBylocalKey: ['hasOrderGuidesActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Accounts',
      pages: [
        {
          link: '^/accountmanager/accounts$'
        },
        {
          link: '^/accountmanager/api-config$'
        },
        {
          link: '^/accountmanager/rfid-config$'
        }
      ]
    },
    {
      moduleName: 'Account Info',
      pages: [
        {
          link: '^/accountmanager/account-info$'
        },
        {
          link: '^/accountmanager/account-info/external-log$'
        },
        {
          link: '^/accountmanager/account-info/adf-logs$'
        }
      ]
    },
    {
      moduleName: 'Brands',
      pages: [
        {
          link: '^/accountmanager/brands$'
        }
      ]
    },
    {
      moduleName: 'Users',
      pages: [
        {
          link: '^/accountmanager/users$'
        }
      ]
    },
    {
      moduleName: 'Subscriptions',
      pages: [
        {
          link: '^/accountmanager/subscriptions$'
        }
      ],
      isForPublisher: true,
      isAllowedForReseller: false,
      isForOldSubscriptionFlow: true
    },
    {
      moduleName: 'Location Subscriptions',
      pages: [
        {
          link: '^/accountmanager/location-subscriptions$'
        }
      ],
      isForPublisher: true,
      isForNewSubscriptionFlow: true
    },
    {
      moduleName: 'Menus',
      pages: [
        {
          link: '^/menumanager/menus$',
          authorizedBylocalKey: ['hasLabelingActivated']
        },
        {
          link: '^/menumanager/menus/[0-9]+/bulk-edit$',
          authorizedBylocalKey: ['hasLabelingActivated']
        },
        {
          link: '^/menumanager/menus/menuitems/[0-9]+/.*$',
          authorizedBylocalKey: ['hasLabelingActivated']
        },
        {
          link: '^/menumanager/menus/menuitems/[0-9]+/.*/catdetail/[0-9]+/.*$',
          authorizedBylocalKey: ['hasLabelingActivated']
        },
        {
          link: '^/menumanager/designer/[0-9]+/.*$',
          authorizedBylocalKey: ['hasLabelingActivated']
        },
        {
          link: '^/menumanager/tempdesigner/[0-9]+/.*$',
          authorizedBylocalKey: ['hasLabelingActivated', 'hasTempConfActivated']
        },
        {
          link: '^/menumanager/menucustomizemenu$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: Modules.RETAIL_CATALOGS,
      pages: [
        {
          link: '^/catalogmanager/catalogs$',
          authorizedBylocalKey: ['hasLabelingActivated']
        },
        {
          link: '^/catalogmanager/catalogs/[0-9]+/products/.*$',
          authorizedBylocalKey: ['hasLabelingActivated']
        },
        {
          link: '^/catalogmanager/product-form$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: Modules.RETAIL_CATALOG_PRODUCTS,
      pages: [
        {
          link: '^/catalogmanager/products$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'GroupTag',
      pages: [
        {
          link: '^/menumanager/grouptags$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Recipes',
      pages: [
        {
          link: '^/menumanager/recipes$',
          authorizedBylocalKey: ['hasLabelingActivated']
        },
        {
          link: '^/menumanager/new-recipe$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Batch Print',
      pages: [
        {
          link: '^/menumanager/batchPrints/[0-9]+/.*$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Menu Items',
      pages: [
        {
          link: '^/menumanager/menuitems$',
          authorizedBylocalKey: ['hasLabelingActivated']
        },
        {
          link: '^/menumanager/menuitems/catdetail/[0-9]+/.*$',
          authorizedBylocalKey: ['hasLabelingActivated']
        },
        {
          link: '^/menumanager/menuitem-form$',
          authorizedBylocalKey: ['hasLabelingActivated'],
          userModulesPermissions: [new UserModulePermissions(Modules.MENU_ITEMS, [Permissions.ADD, Permissions.VIEW, Permissions.EDIT])]
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Locations',
      pages: [
        {
          link: '^/locationsmanager/locations$'
        },
        {
          link: '^/locationsmanager/locations/[0-9]+/[^/]*$'
        },
        {
          link: '^/locationsmanager/customizemenu$'
        },
        {
          link: '^/locationsmanager/locations/[0-9]+/.+/temp-schedule-customizer$',
          userModulesPermissions: [new UserModulePermissions(Modules.TEMP_SCHEDULE, [Permissions.EDIT])]
        },
        {
          link: '^/locationsmanager/locations/[0-9]+/.+/temp-tasks-bulk-editor/[0-9]+$',
          userModulesPermissions: [new UserModulePermissions(Modules.CHECKLISTS, [Permissions.EDIT])]
        },
        {
          link: '^/locationsmanager/equipmentdetails$',
          authorizedBylocalKey: ['hasTempConfActivated', 'hasSenseConfActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Browse Location',
      pages: [
        {
          link: '^/locationsmanager/browse-location',
          authorizedBylocalKey: ['isReseller']
        }
      ]
    },
    {
      moduleName: 'Master Location Code',
      pages: [
        {
          link: '^/locationsmanager/multistores$',
          authorizedBylocalKey: ['hasMultiStoreCodeActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Equipment Types',
      pages: [
        {
          link: '^/locationsmanager/equipmenttypes$',
          authorizedBylocalKey: ['hasTempConfActivated', 'hasSenseConfActivated']
        },
        {
          link: '^/locationsmanager/equipmenttype$',
          authorizedBylocalKey: ['hasTempConfActivated', 'hasSenseConfActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Devices',
      pages: [
        {
          link: '^/locationsmanager/devices$'
        }
      ]
    },
    {
      moduleName: 'Roles and Permissions',
      pages: [
        {
          link: '^/accountmanager/roles$'
        },
        {
          link: '^/accountmanager/roles/[0-9]+/.*$'
        }
      ]
    },
    {
      moduleName: 'Label Designer',
      pages: [
        {
          link: '^/labeldesigner/labels$',
          authorizedBylocalKey: ['hasLabelingActivated', 'hasTimersActivated']
        },
        {
          link: '^/labeldesigner/labels/editor/([0-9]+|newLabel|importLabel)$',
          authorizedBylocalKey: ['hasLabelingActivated', 'hasTimersActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Template Designer',
      pages: [
        {
          link: '^/labeldesigner/templates$',
          authorizedBylocalKey: ['hasLabelingActivated']
        },
        {
          link: '^/labeldesigner/templates/editor/([0-9]+|newTemplate|importTemplate)$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ]
    },
    {
      moduleName: 'Workflow Designer',
      pages: [
        {
          link: '^/workflow$',
          authorizedBylocalKey: ['hasWorkflowActivated']
        },
        {
          link: '^/workflow/editor$',
          authorizedBylocalKey: ['hasWorkflowActivated']
        },
        {
          link: '^/workflow/editor/.*$',
          authorizedBylocalKey: ['hasWorkflowActivated']
        }
      ]
    },
    {
      moduleName: 'Widget Designer',
      pages: [
        {
          link: '^/labeldesigner/widgets$',
          authorizedBylocalKey: ['hasLabelingActivated']
        },
        {
          link: '^/labeldesigner/widgets/editor/([0-9]+|newWidget|importWidget)$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ]
    },
    {
      moduleName: 'Ingredients',
      pages: [
        {
          link: '^/menumanager/ingredients$',
          authorizedBylocalKey: ['hasLabelingActivated', 'hasTempConfActivated']
        },
        {
          link: '^/menumanager/ingredients/catdetail/[0-9]+/.*$',
          authorizedBylocalKey: ['hasLabelingActivated', 'hasTempConfActivated']
        },
        {
          link: '^/menumanager/ingredient-form$',
          authorizedBylocalKey: ['hasLabelingActivated', 'hasTempConfActivated'],
          userModulesPermissions: [new UserModulePermissions(Modules.INGREDIENTS, [Permissions.ADD, Permissions.VIEW, Permissions.EDIT])]
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Data Recovery',
      pages: [
        {
          link: '^/settings/data-recovery$'
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Nutrition Facts',
      pages: [
        {
          link: '^/nutritionfacts/profiles$'
        },
        {
          link: '^/nutritionfacts/profiles/[0-9]+/profileEditor$'
        },
        {
          link: '^/nutritionfacts/profiles/profileEditor$'
        }
      ]
    },
    {
      moduleName: 'Styles',
      pages: [
        {
          link: '^/settings/styles$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: Modules.SCALE_CONTAINERS,
      pages: [
        {
          link: '^/settings/scale-containers$',
          authorizedBylocalKey: ['hasScaleModels']
        },
        {
          link: '^/settings/order-scale-containers$',
          authorizedBylocalKey: ['hasScaleModels']
        }
      ],
      isAllowedForReseller: false,
      userModulesPermissions: [new UserModulePermissions(Modules.SCALE_CONTAINERS, [Permissions.ADD, Permissions.VIEW, Permissions.EDIT])]
    },
    {
      moduleName: 'Media Manager',
      pages: [
        {
          link: '^/mediamanager/media$',
          authorizedBylocalKey: ['hasMediaActivated']
        },
        {
          link: '^/mediamanager/media/catdetail/[0-9]+/.*$',
          authorizedBylocalKey: ['hasMediaActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Profile',
      pages: [
        {
          link: '^/profilemanager/profile$'
        }
      ],
      isAllowedToAll: true
    },
    {
      moduleName: 'Dashboard',
      pages: [
        {
          link: '^/dashboard$'
        }
      ],
      isAllowedToAll: true
    },
    {
      moduleName: 'Addons',
      pages: [
        {
          link: '^/menumanager/addons$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Custom Fields',
      pages: [
        {
          link: '^/settings/custom-fields$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Label Fonts',
      pages: [
        {
          link: '^/settings/label-fonts$'
        },
        {
          link: '^/settings/label-fonts/editor$'
        },
        {
          link: '^/settings/label-fonts/editor/[0-9]+$'
        }
      ]
    },
    {
      moduleName: 'Temp Workflows Templates',
      pages: [
        {
          link: '^/settings/temp-workflow-templates$',
          authorizedBylocalKey: ['hasResellerTempConfActivated']
        },
        {
          link: '^/settings/temp-workflow-templates/[0-9]+/tasks$',
          authorizedBylocalKey: ['hasResellerTempConfActivated']
        }
      ]
    },
    {
      moduleName: 'Retail Workflow Templates',
      pages: [
        {
          link: '^/settings/retail-workflow-templates$'
        }
      ],
      isAllowedForReseller: true,
      userModulesPermissions: [new UserModulePermissions(Modules.RETAIL_WORKFLOW_TEMPLATES, [Permissions.VIEW])]
    },
    {
      moduleName: 'Temp Schedule',
      pages: [
        {
          link: '^/settings/tempschedule$',
          authorizedBylocalKey: ['hasTempConfActivated', LocalStorageFlags.HasAdvancedChecklistActivated]
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Temp Workflows',
      pages: [
        {
          link: '^/settings/workflow-templates$',
          authorizedBylocalKey: ['hasTempConfActivated']
        }
      ]
    },
    {
      moduleName: 'Analytics',
      pages: [
        {
          link: '^/analytics/report$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Reports On Demand',
      pages: [
        {
          link: '^/analytics/report-on-demand$',
          authorizedBylocalKey: ['hasLabelingActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Audit Trail',
      pages: [
        {
          link: '^/analytics/audit$'
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Media Dashboard',
      pages: [
        {
          link: '^/analytics/media-dashboard$',
          authorizedBylocalKey: ['hasMediaActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Pricing Dashboard',
      pages: [
        {
          link: '^/analytics/pricing-dashboard'
        }
      ],
      isAllowedForReseller: false,
      userModulesPermissions: [new UserModulePermissions(Modules.RETAIL_PRICING_DASHBOARD, [Permissions.VIEW])]
    },
    {
      moduleName: 'Temp Dashboard',
      pages: [
        {
          link: '/analytics/temp-dashboard-v2',
          authorizedBylocalKey: ['hasTempActivated']
        },
        {
          link: '^/analytics/report-on-demand$',
          authorizedBylocalKey: ['hasTempActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Sense Dashboard',
      pages: [
        {
          link: '^/analytics/sense-dashboard$',
          authorizedBylocalKey: ['hasSenseActivated']
        },
        {
          link: '^/analytics/temp-report-drill-down$',
          authorizedBylocalKey: ['hasTempConfActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Checklist Report',
      pages: [
        {
          link: '^/analytics/checklist-reports$',
          authorizedBylocalKey: ['hasChecklistReportsActivated'],
          userModulesPermissions: [new UserModulePermissions(Modules.CHECKLIST_REPORT, [Permissions.VIEW])]
        },
        {
          link: '^/analytics/checklist-email-reports$',
          authorizedBylocalKey: ['hasChecklistReportsActivated'],
          userModulesPermissions: [new UserModulePermissions(Modules.CHECKLIST_REPORT, [Permissions.VIEW])]
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Checklists',
      pages: [
        {
          link: '^/checklist$',
          authorizedBylocalKey: ['hasTempConfActivated', 'hasChecklistActivated', 'hasAdvancedChecklistActivated']
        },
        {
          link: '^/checklist/list$',
          authorizedBylocalKey: ['hasTempConfActivated', 'hasAdvancedChecklistActivated']
        },
        {
          link: '^/checklist/list/[0-9]+/bulk-edit$',
          authorizedBylocalKey: ['hasTempConfActivated', 'hasAdvancedChecklistActivated']
        },
        {
          link: '^/checklist/[0-9]+/bulk-edit$',
          authorizedBylocalKey: ['hasTempConfActivated'],
          userModulesPermissions: [new UserModulePermissions(Modules.CHECKLISTS, [Permissions.Bulk])]
        },
        {
          link: '^/checklist/temp/design-checklist$',
          authorizedBylocalKey: ['hasTempConfActivated']
        },
        {
          link: '^/checklist/ops/[^/]+/tasks$',
          authorizedBylocalKey: ['hasChecklistActivated']
        },
        {
          link: '^/checklist/temp/[0-9]+$',
          authorizedBylocalKey: ['hasTempConfActivated']
        },
        {
          link: '^/checklist/ops/[0-9]+$',
          authorizedBylocalKey: [LocalStorageFlags.HasAdvancedChecklistActivated]
        },
        {
          link: '^/checklist/employee/[0-9]+$',
          authorizedBylocalKey: [LocalStorageFlags.HasAdvancedChecklistActivated]
        },
        {
          link: '^/checklist/ops/list$',
          authorizedBylocalKey: ['hasChecklistActivated']
        },
        {
          link: '^/settings/checklist/ops/checklist-options$',
          authorizedBylocalKey: ['hasChecklistActivated']
        },
        {
          link: '^/settings/checklist/ops/checklist-options/details/[^/]+/.*$',
          authorizedBylocalKey: ['hasChecklistActivated']
        },
        {
          link: '^/checklist/list/[0-9]+/temp-tasks-bulk-editor/[0-9]+$',
          userModulesPermissions: [new UserModulePermissions(Modules.CHECKLISTS, [Permissions.EDIT])]
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Checklist Dashboard',
      pages: [
        {
          link: '^/analytics/advanced-checklist$',
          authorizedBylocalKey: ['hasChecklistActivated', 'hasAdvancedChecklistActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: 'Checklist Options',
      pages: [
        {
          link: '^/settings/checklist-options$',
          authorizedBylocalKey: ['isReseller', 'hasAdvancedChecklistActivated']
        },
        {
          link: '^/settings/checklist-options/[0-9]+$',
          authorizedBylocalKey: ['isReseller', 'hasAdvancedChecklistActivated']
        }
      ]
    },
    {
      moduleName: 'Logs',
      pages: [
        {
          link: '^/logs$'
        }
      ]
    },
    {
      moduleName: 'Reseller Manager',
      pages: [
        {
          link: '^/resellers$'
        }
      ]
    },
    {
      moduleName: 'Release Manager',
      pages: [
        {
          link: '^/releasemanager/release-notes$',
          userModulesPermissions: [new UserModulePermissions(Modules.RELEASE_NOTES, [Permissions.EDIT])]
        },
        {
          link: '^/releasemanager/release-notes-template$'
        }
      ],
      isAllowedToAll: true
    },
    {
      moduleName: 'Translation',
      pages: [
        {
          link: '^/settings/data-translation$'
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: Modules.PRINTER_TYPES,
      pages: [
        {
          link: '^/settings/printer-models$',
          authorizedBylocalKey: ['isReseller']
        }
      ]
    },
    {
      moduleName: Modules.APP_RELEASE_NOTES,
      pages: [
        {
          link: '^/settings/app-release-notes$',
          authorizedBylocalKey: ['isReseller']
        },
        {
          link: '^/settings/app-release-notes/editor$',
          authorizedBylocalKey: ['isReseller']
        },
        {
          link: '^/settings/app-release-notes/editor/[0-9]+$',
          authorizedBylocalKey: ['isReseller']
        }
      ]
    },
    {
      moduleName: Modules.TIMER_MANAGER,
      pages: [
        {
          link: '^/timermanager/templates$',
          authorizedBylocalKey: ['hasTimersActivated']
        },
        {
          link: '^/timermanager/timer-form$',
          authorizedBylocalKey: ['hasTimersActivated']
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: Modules.SCALE_MODELS,
      pages: [
        {
          link: '^/settings/scale-models$',
          authorizedByLocalKey: ['isReseller']
        }
      ]
    },
    {
      moduleName: Modules.DISTRIBUTION_CENTERS,
      pages: [
        {
          link: '^/distributor-manager/distribution-centers$',
          authorizedBylocalKey: [LocalStorageFlags.hasDistributionUsersActivated],
          userModulesPermissions: [new UserModulePermissions(Modules.DISTRIBUTION_CENTERS, [Permissions.ADD, Permissions.EDIT])]
        }
      ],
      isAllowedForReseller: false
    },
    {
      moduleName: Modules.ORDER_MANAGEMENT,
      pages: [
        {
          link: '^/distributor-manager/orders-management$',
          authorizedBylocalKey: [LocalStorageFlags.hasOrderManagementActivated]
        }
      ],
      isAllowedForReseller: false
    }
  ],
  allSidenavSection: [
    {
      mainSection: {
        title: 'menu.dashboard',
        link: '/dashboard'
      },
      hasSubSections: false,
      order: 0,
      icon: 'menu-icons/MenuIcon-Dash.svg',
      alwaysVisible: true
    },
    {
      mainSection: {
        title: 'menu.acct_mgmnt'
      },
      hasSubSections: true,
      order: 1,
      icon: 'menu-icons/MenuIcon-Account-management.svg',
      isToggled: false,
      subSections: [
        {
          title: 'menu.accounts',
          link: '/accountmanager/accounts'
        },
        {
          title: 'menu.account-info',
          link: '/accountmanager/account-info'
        },
        {
          title: 'menu.users',
          link: '/accountmanager/users'
        },
        {
          title: 'menu.brands',
          link: '/accountmanager/brands'
        },
        {
          title: 'menu.roles',
          link: '/accountmanager/roles'
        },
        {
          title: 'menu.subscriptions',
          link: '/accountmanager/subscriptions'
        },
        {
          title: 'menu.subscriptions',
          link: '/accountmanager/location-subscriptions'
        }
      ],
      alwaysVisible: false
    },
    {
      mainSection: {
        title: 'menu.resellers',
        link: '/resellers'
      },
      hasSubSections: false,
      order: 0,
      icon: 'menu-icons/MenuIcon-LocationManager.svg',
      alwaysVisible: false
    },
    {
      mainSection: {
        title: 'menu.label_designer',
        link: '/labeldesigner/labels'
      },
      hasSubSections: true,
      order: 2,
      icon: 'menu-icons/MenuIcon-LabelManager.svg',
      alwaysVisible: false,
      subSections: [
        {
          title: 'menu.labels',
          link: '/labeldesigner/labels'
        },
        {
          title: 'menu.widgets',
          link: '/labeldesigner/widgets'
        },
        {
          title: 'menu.templates',
          link: '/labeldesigner/templates'
        }
      ]
    },
    {
      mainSection: {
        title: 'menu.workflow-designer',
        link: '/workflow'
      },
      hasSubSections: false,
      order: 3,
      icon: 'menu-icons/MenuIcon-WorkflowManager.svg',
      alwaysVisible: false
    },
    {
      mainSection: {
        title: 'menu.menu_mgr',
        link: '/menumanager/menus'
      },
      hasSubSections: false,
      icon: 'menu-icons/MenuIcon-MenuManager.svg',
      alwaysVisible: false,
      childTabsLinks: ['/menumanager/menus', '/menumanager/menuitems', '/menumanager/ingredients', '/menumanager/addons', '/menumanager/grouptags', '/menumanager/recipes']
    },
    {
      mainSection: {
        title: 'menu.catalog_mgr',
        link: '/catalogmanager/catalogs'
      },
      hasSubSections: false,
      icon: 'menu-icons/MenuIcon-CatalogManager.svg',
      alwaysVisible: false,
      childTabsLinks: ['/catalogmanager/catalogs', '/catalogmanager/products']
    },
    {
      mainSection: {
        title: 'menu.checklist_manager',
        link: '/checklist'
      },
      hasSubSections: false,
      order: 7,
      icon: 'menu-icons/MenuIcon-Checklists.svg',
      alwaysVisible: false
    },
    {
      mainSection: {
        title: 'menu.loc_mgr'
      },
      hasSubSections: true,
      order: 4,
      icon: 'menu-icons/MenuIcon-LocationManager.svg',
      isToggled: false,
      subSections: [
        {
          title: 'menu.locations',
          link: '/locationsmanager/locations'
        },
        {
          title: 'menu.multi-stores',
          link: '/locationsmanager/multistores'
        },
        {
          title: 'menu.devices',
          link: '/locationsmanager/devices'
        },
        {
          title: 'menu.equipment-types',
          link: '/locationsmanager/equipmenttypes'
        },
        {
          title: 'menu.browse_locations',
          link: '/locationsmanager/browse-location'
        }
      ],
      alwaysVisible: false
    },
    {
      mainSection: {
        title: 'menu.hardware_manager'
      },
      hasSubSections: true,
      order: 6,
      icon: 'menu-icons/MenuIcon-HardwareManager.svg',
      isToggled: false,
      subSections: [
        {
          title: 'menu.hardware_devices',
          link: '/hardware-manager/hardware-devices'
        }
      ],
      alwaysVisible: false
    },
    {
      mainSection: {
        title: 'menu.media_mgr',
        link: '/mediamanager/media'
      },
      hasSubSections: false,
      order: 5,
      icon: 'menu-icons/MenuIcon-MediaManager.svg',
      alwaysVisible: false
    },
    {
      mainSection: {
        title: 'menu.inventory_manager'
      },
      hasSubSections: true,
      order: 6,
      icon: 'menu-icons/MenuIcon-InventoryManager.svg',
      isToggled: false,
      subSections: [
        {
          title: 'menu.ordering',
          link: '/inventory-manager/orders'
        },
        {
          title: 'menu.invoices',
          link: '/inventory-manager/invoices'
        },
        {
          title: 'menu.order_guides',
          link: '/inventory-manager/order-guides'
        }
      ],
      alwaysVisible: false
    },
    {
      mainSection: {
        title: 'menu.timer_manager',
        link: '/timermanager/templates'
      },
      icon: 'menu-icons/timer.svg',
      hasSubSections: false,
      alwaysVisible: false
    },
    {
      mainSection: {
        title: 'menu.analytics'
      },
      hasSubSections: true,
      order: 8,
      icon: 'menu-icons/MenuIcon-Report.svg',
      isToggled: false,
      splitSubSections: true,
      dashboardSections: ['menu.label-analytics', 'menu.media-analytics', 'menu.checklist-analytics', 'menu.temp-analytics', 'menu.sense-analytics', 'menu.retail_pricing_dashboard'],
      subSections: [
        {
          title: 'menu.labeling-dashboard',
          link: '/analytics/report',
          dashboardSection: 'menu.label-analytics',
          icon: 'menu-icons/Labeling Dashboard.svg'
        },
        {
          title: 'menu.media-dashboard',
          link: '/analytics/media-dashboard',
          dashboardSection: 'menu.media-analytics',
          icon: 'menu-icons/Media Dashboard.svg'
        },
        {
          title: 'menu.checklist_dashboard',
          link: '/analytics/advanced-checklist',
          dashboardSection: 'menu.checklist-analytics',
          icon: 'menu-icons/Checklist Dashboard.svg'
        },
        {
          title: 'menu.temp_dashboard-v2',
          link: '/analytics/temp-dashboard-v2',
          dashboardSection: 'menu.temp-analytics',
          noTitleCase: true,
          icon: 'menu-icons/Temp & HACCP Dashboard.svg'
        },
        {
          title: 'menu.sense_dashboard',
          link: '/analytics/sense-dashboard',
          dashboardSection: 'menu.sense-analytics',
          noTitleCase: true,
          icon: 'menu-icons/Sense Dashboard.svg'
        },
        {
          title: 'menu.retail_pricing_dashboard',
          link: '/analytics/pricing-dashboard',
          dashboardSection: 'menu.retail_pricing_dashboard',
          icon: 'menu-icons/MenuIcon-Report.svg'
        },
        {
          title: 'menu.checklist_reports',
          link: '/analytics/checklist-reports'
        },
        {
          title: 'menu.reports_on_demand',
          link: '/analytics/report-on-demand'
        },
        {
          title: 'menu.audit_trail',
          link: '/analytics/audit'
        }
      ],
      alwaysVisible: false
    },
    {
      mainSection: {
        title: 'menu.nutrition_facts',
        link: '/nutritionfacts/profiles'
      },
      hasSubSections: false,
      order: 9,
      icon: 'menu-icons/MenuIcon-NutritionFact.svg',
      alwaysVisible: false
    },
    {
      mainSection: {
        title: 'menu.settings'
      },
      hasSubSections: true,
      order: 10,
      icon: 'menu-icons/MenuIcon-Settings.svg',
      alwaysVisible: false,
      subSections: [
        {
          title: 'menu.styles',
          link: '/settings/styles'
        },
        {
          title: 'menu.custom_fields',
          link: '/settings/custom-fields'
        },
        {
          title: 'menu.label_size',
          link: '/settings/labelsize'
        },
        {
          title: 'menu.scale_containers',
          link: '/settings/scale-containers'
        },
        {
          title: 'menu.temp_workflow_templates',
          link: '/settings/temp-workflow-templates'
        },
        {
          title: 'menu.retail_workflow_templates',
          link: '/settings/retail-workflow-templates'
        },
        {
          title: 'menu.temp_schedule',
          link: '/settings/tempschedule'
        },
        {
          title: 'menu.temp_workflows',
          link: '/settings/workflow-templates'
        },
        {
          title: 'menu.ops_checklist_options',
          link: '/settings/checklist/ops/checklist-options'
        },
        {
          title: 'menu.checklist_options',
          link: '/settings/checklist-options'
        },
        {
          title: 'menu.label_fonts',
          link: '/settings/label-fonts'
        },
        {
          title: 'data_recovery',
          link: '/settings/data-recovery'
        },
        {
          title: 'reporting_hierarchy.title',
          link: '/settings/hierarchies'
        },
        {
          title: 'data-translation.title',
          link: '/settings/data-translation'
        },
        {
          title: 'menu.printer_models',
          link: '/settings/printer-models'
        },
        {
          title: 'menu.app_release_notes',
          link: '/settings/app-release-notes'
        },
        {
          title: 'menu.scale_models',
          link: '/settings/scale-models'
        },
        {
          title: 'menu.device_models',
          link: '/settings/device-models'
        }
      ]
    },
    {
      mainSection: {
        title: 'menu.help_support',
        notIncludedInDashboard: true
      },
      hasSubSections: true,
      order: 11,
      icon: 'menu-icons/KnowledgeBase.svg',
      subSections: [
        {
          title: HelpAndSupport.HELP_CENTER
        },
        {
          title: HelpAndSupport.SUPPORT_CODE
        }
      ]
    },
    {
      mainSection: {
        title: 'menu.distributor_manager'
      },
      hasSubSections: true,
      order: 14,
      icon: 'menu-icons/MenuIcon-DistributorMgr.svg',
      isToggled: false,
      subSections: [
        {
          title: 'menu.distribution_centers',
          link: '/distributor-manager/distribution-centers'
        },
        {
          title: 'menu.orders_management',
          link: '/distributor-manager/orders-management'
        }
      ],
      alwaysVisible: false
    }
  ]
};
